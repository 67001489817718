import {React ,Component} from 'react';
import ReactDOM from 'react-dom';
import MyMap from './MyMap';
import 'antd/dist/antd.css';
import raport from './raport.png';
import { Layout, Menu, Breadcrumb, Typography, Divider , Button} from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;


class App extends Component {
  state = {
    collapsed: false,
    showText: false
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  onshowTesxt = () => {
    this.setState({showText: true})
  }
  render(){
    return(
    <Router>

  <div className="App">
<Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logo" >
           Drone Grow
          </div>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">

            <Menu.Item key="1" icon={<DesktopOutlined />}>
              <Link to="/">Home</Link>

            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
            <Link to="/map">Map</Link>

            </Menu.Item>
            <Menu.Item key="3" icon={<TeamOutlined />}>
            <Link to="/more">More</Link>

            </Menu.Item>

            <Menu.Item key="4" icon={<FileOutlined />}>
              <Link to="/org">              Organisation concept
</Link>

            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
         
          <Content style={{ margin: '0 16px' }}>

          <Switch>
          <Route path="/org">
            <>
          <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Organisation concept</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <h2></h2>
              <span style={{fontSize : "1.5em"}}>
              <ul>
                <h3>Hard equipment and hinterland</h3>
<li>	Scout drone : https://megadron.pl/pl/products/parrot-bluegrass-8590.html?fbclid=IwAR2h10jJMlU6vYMxWPRvQ0sbf6txMszXZeB9vH7ZbLM8qPr2oFzdk7g5DfA</li>
<li>	Executive drone : https://megadron.pl/pl/products/dji-agras-t30-25779.html</li>
<li>	Warehouse for the storage of fertilizers and plant protection products</li>
<li>	Office for R&D (could be connected with warehouse)</li>
<li>	Cross-country vehicle</li>
<li>	Several off-road vehicles capable of transporting the drone and fertilizers</li>
<br/>
<br/>
<h3>	Crew</h3>
<li>The number of people in each position depends on the stage of development of the company. Some workstations can be outsourced.</li>
<li>	Us</li>
<li>	Support staff member </li>
<li>	Scout drone operator</li>
<li>	Executive drone operator</li>
<li>	Warehouseman</li>
<li>	Fertiliser specialist (possible outsourced)</li>
<li>	Procurement specialist</li>
<li>	Accountant (possible outsourced)</li>
<li>	Crop management specialist (possible outsourced)</li>
<li>	Salesman </li>
<br/>
<br/>
<h3>	Application</h3>
<li>	The application automatically downloads satellite images taken by sentinel-2 from designated areas.</li>
<li>	Based on the data received, the application creates a report on the status of the land data.</li>
<li>	Through machine learning, the application will automatically detect soil drainage, lack of a particular fertilizer or possible diseases.</li>
<li>	After receiving information from the reconnaissance drone, the application looks for a solution to the problem in question and shall dose the correct quantities of plant protection products</li>
</ul>
</span>
            </div>
            </>
          </Route>
          <Route path="/map">
            <>
          <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Map</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <MyMap cliHandler={this.onshowTesxt}></MyMap>
              <Divider/>
              <img src={raport} alt="raport" style={{width: "50%"}}/>
            </div>
            </>
          </Route>
          <Route path="/more">
          <>
          <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>More</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <h2>Conception workflow
</h2>
<span style={{fontSize : "1.5em"}}>
In addition to allowing the customer to report back to the company, the service will download images from the Sentinel-2 service so that we can report on the status of crops in real time. It also gives the possibility to contact farmers who are not yet using our service, so we can offer them cooperation.
	Based on the sentinel-2 images, the application produces a report (e.g. once a week), which is generated to the customer's email address as part of the subscriber service, along with a comprehensive option for solving potential problems. 
</span>
<br/>
<br/>
<h2>Example of cooperation with a client:</h2>
<span style={{fontSize : "1.5em"}}>
<ul>
<li>	The customer wants to check the condition of his agricultural crops according to the parameters he has selected</li>
<li>	The customer sends us the area (e.g. geographical coordinates) where he wants the crop assessment to be carried out</li>
<li>	Our application, based on the coordinates provided, retrieves the current site grid with the division into plots</li>
<li>	The scout drone operator (an employee of the company) goes to the designated area and coordinates all the drone's actions related to energy consumption, take-offs, landings and maintenance. The drone, on the basis of a preloaded grid of the target area, plans its route in such a way as to precisely photograph the current state of the designated area.</li>
<li>	The condition of the terrain, commented on by the scout drone, is uploaded to the app to analyze the needs of the terrain and crops. Based on many other images (machine learning), the application will be able to recognize the needs of the area.</li>
<li>Operator sends information to the server. After that the operator goes to another field.</li>
<li>	In the headquarters, on the basis of the information received, a report is prepared for the customer, which includes information about his crops (irrigation, soil condition, stage of the vegetative period of crops, or other needs to which the customer pays attention). </li>
<li>	This report is sent to the customer and contains the necessary information on the state of the crop, proposals for solving the problem (choice of fertilizer or spraying, its quantity and price for this service). </li>
<li>	The customer chooses one of several options: the first is to thank us for our services at the location and pay for the report received, the second is to choose to have our drone take care of crop improvement using resources provided by the customer, and the third option which is a complete offer in which the operator goes to the location and, using the appropriate resources taken from the warehouse, performs the task assigned to him. After choosing a specific option, the client receives a report with information about the selected fertilizers (if the third option was chosen) and the price of the service.</li>
<li>	Upon receipt of a reply from the customer the appropriate drone is selected to perform the task of fertilization or plant protection products. The drone operator takes the necessary fertilizers or plant protection products (if needed)  from the warehouse and travels to the destination area.</li>
<li>	Upon arrival at the site, the operator supervises the drone, but the drone itself determines the flight route and the amount of necessary resources on the basis of a previously generated report. The operator's tasks also include replenishing the drone with appropriate fertilizers and supervising the drone's energy consumption.</li>
<li>	Once the work has been carried out, the customer receives a message that the work has been completed. A support employee suggests buying a subscription service to monitor the status of the crop weekly or monthly.</li>
</ul></span>
<br/>





            </div>
            </>
          </Route>
          <Route path="/">
            
          <>
          <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <h2>Introduction of Drone Grow</h2>
<span style={{fontSize : "1.5em"}}>
Main idea of this project is to create a service for agricultural landowner’s. Service would help them manage and control the condition of their agricultural fields in aspects of irrigation, fertilization and overall soil condition.  Our product will be an intermediary service that after getting a map of terrain from Sentinel-2 satellite we will calculate which areas need irrigation, fertilization or other types of help. With that we can reach out to the owners of those fields offering them free insides made by our algorithms from satellite photos and a list of our services that could help in that case.
</span>
<br/>
<br/>

<Link to="/map">
<Button type="primary" shape="round" size={"large"} >
Go to map
</Button>
</Link>

            </div>
            </>
          </Route>
        </Switch>

          </Content>
          <Footer style={{ textAlign: 'center' }}>Drone Grow</Footer>
        </Layout>
      </Layout>
  </div>
  </Router>
    )
  }};

export default App;
