import {React ,useEffect, useRef, useMemo} from 'react';
import  L from "leaflet";
import Proj from "proj4leaflet";
import { MapContainer, TileLayer, Marker, Popup, WMSTileLayer, useMap, Polygon} from 'react-leaflet'
import { Button } from 'antd';
import { useLeafletContext } from "@react-leaflet/core";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import proj4 from "proj4";
window.proj4 = proj4;


const dzialka = [
  [53.518691, 17.812189],
  [53.518276, 17.817893],
  [53.509517, 17.818108],
  [53.509609, 17.812331]
]

export const CRS_PL = new Proj.CRS('EPSG:2180',
'+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
{
  resolutions: [8192, 4096, 2048],
}
)

const redOptions = { color: 'red' }

const DzialkaLayer = ({clickHandler}) => {
  const eventHandlers = useMemo(
    () => ({
      click() {
        clickHandler();
      },
    }),
    [],
  )
  return(
    <Polygon eventHandlers={eventHandlers} pathOptions={redOptions} positions={dzialka} />

  )
}

const GeoImageLayer = ({ url, options }) => {
  const geoTiffLayerRef = useRef();
  const context = useLeafletContext();
  const map = useMap();

  useEffect(() => {
    const container = context.layerContainer || context.map;

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        parseGeoraster(arrayBuffer).then((georaster) => {
          console.log("georaster:", georaster);
          options.georaster = georaster;
          geoTiffLayerRef.current = new GeoRasterLayer(options);
          container.addLayer(geoTiffLayerRef.current);
          geoTiffLayerRef.current.bringToBack();
        });
      });

    return () => {
      container.removeLayer(geoTiffLayerRef.current);
    };
  }, [context, url, map, options]);

  return null;
};

const tiffUrl =
"https://storage.gra.cloud.ovh.net/v1/AUTH_57c1a57e3c334a7a92272b69f067ac30/hackatoon-data/eotif.tif";


const options = {
pixelValuesToColorFn: (values) => {
  // transforming single value into an rgba color
  const nir = values[0];

  if (nir === 0) return;
  // console.log("nir:", nir);
  const r = (nir ) * 255;
  const g = (nir ) * 255;
  const b = (nir ) * 255;
  return `rgba(${r},${g},${b}, 1)`;
},
resolution: 64,
opacity: 1
};

const MyMap = ({cliHandler}) => (
  <div className="App">
    <MapContainer
    className='Map'
    center={[53.5143754,17.8110566]}
    zoom={16}
    scrollWheelZoom={true}
    >

        <WMSTileLayer
    url="https://integracja02.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow"
    layers='dzialki'
    crs = {CRS_PL}
    transparent={true}
    format='image/png'
    styles=',,,,'
    uppercase={true}
    tileSize={128}
    
  />
      <GeoImageLayer url={tiffUrl} options={options} />

      <DzialkaLayer clickHandler = {cliHandler}></DzialkaLayer>



</MapContainer>
  </div>
);

export default MyMap;
